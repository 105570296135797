<template>
	<div id="eventRoomScheduleView">
		<div>
			<div class="row">
				<div class="col-12">
				</div>
			</div>
			<div class="row">
				<div class="col-12">
					<div class="card">
						<div class="card-header">
							<h6>
								<i class="bi bi-calendar2-event me-1"></i> {{ eventData.event_title }}
								<i class="bi bi-geo-alt-fill ms-5 me-1"></i> {{ roomData.room_name }}
							</h6>
						</div>
						<div class="card-body">
							<div v-if="isSyncMachine" class="">
								<h6 class="text-success mb-4 border-bottom pb-1">
									<img src="https://meetingfiles.com/assets/images/sync/sync-icon-white.png" height="24" class="me-1"/>
									Sync Machine: {{ syncStation.station_name }} | Update in:
									<span>{{ updateTimer }} </span>
									<button @click.prevent.stop="stopSync" type="button" class="btn btn-link text-danger text-decoration-none ms-5">
										<i class="bi bi-x-octagon"></i> Stop Sync
									</button>
								</h6>
							</div>
							<div v-else class="card card-body mb-3">

								<h6 class="text-danger"><i class="bi bi-exclamation-triangle-fill"></i> Not Syncing!
									<button type="button" class="btn btn-warning btn-sm ms-3" data-bs-toggle="collapse" data-bs-target="#machineSelectContainer" aria-expanded="false" aria-controls="machineSelectContainer">Start Sync...</button>
								</h6>

								<div class="collapse" id="machineSelectContainer">
									<p><strong>Name this Station to start Sync!</strong></p>
									<div v-if="availableSyncStations">
										<select @change="selectSyncStation($event)" class="form-select">
											<option value="" selected>Name this Sync Station</option>
											<option v-for="syncStation in availableSyncStations" :key="syncStation.uuid" :value="syncStation.sync_stationsID">{{ syncStation.station_name }}</option>
										</select>
									</div>
									<div v-else>
										<p class="alert alert-warning">
											<i class="bi bi-exclamation-triangle-fill"></i> There are no sync machines for this event. Edit the event to add sync machines.
										</p>
									</div>
								</div>
							</div>
							<div v-if="presentationsArray">
								<ul class="nav nav-tabs" id="myTab" role="tablist">
									<li class="nav-item" role="presentation" v-for="(value, key, index) in presentationsArray" :key="key">
										<button class="nav-link" :class="index===0 ? 'active' : ''" @click="setTabDate(key)" v-bind:id="key+'tab'" data-bs-toggle="tab" v-bind:data-bs-target="'#date'+key" type="button" role="tab" v-bind:aria-controls="key" v-bind:aria-selected="index===0?'true':'false'">
											<span v-html="tabDate(key)"></span></button>
									</li>
								</ul>
								<div class="tab-content" id="myTabContent">
									<div v-for="(value, key, index) in presentationsArray" :key="key" v-bind:id="'date'+key" class="tab-pane fade" :class="index===0 ? 'active show' : ''" role="tabpanel" v-bind:aria-labelledby="key+'tab'">
										<table class="table table-responsive table-bordered">
											<thead>
											<tr>
												<th class="ps-3"><input type="checkbox" @click='runCheckAll()' v-model='isCheckAll'/> Check All</th>
											</tr>
											</thead>
											<tbody>
												<tr v-for="presentation in value" :key="presentation.presentation_scheduleID" >
													<td>
														<table class="table table-responsive">
															<tr v-if="presentation.admin_notes">
																<td colspan="7" class="note-td">
																	<p class="text-dark">{{ presentation.admin_notes }}</p>
																</td>
															</tr>
															<tr :class="{'sync-new-version-row' : !compareRevision(presentation)}">
																<td class="td-dl">
																	<input type="checkbox" v-if="presentation.presentationFile" v-model="downloadIDs" :value="presentation.presentation_scheduleID" @change="handleDownloadCheckbox()"/>
																</td>
																<td class="td-time" contenteditable="true"
                                    v-on:keydown.enter="updateTime($event, presentation)"
                                    v-on:blur="updateTime($event, presentation)">{{ presentationTime(presentation.presentation_time) }}</td>
																<td class="td-presenter">
																	<a target="_blank" v-bind:href="getPresenterLink(presentation)">{{ presentation.presenter.presenters_lname }} {{ presentation.presenter.presenters_fname }}</a>
																</td>
																<td class="td-type"><em class="small" v-if="presentation.presentationFile">{{getFileTypeSource(presentation.presentationFile.file_typesID)}}</em></td>
																<td class="td-title" contenteditable="true"
                                    v-on:keydown.enter="updateTitle($event, presentation)"
                                    v-on:blur="updateTitle($event, presentation)">{{ presentation.presentation_title }}</td>
																<td class="td-rev">{{ revisionCount(presentation) }}</td>
																<td class="td-buttons"><button @click="createNoteClicked(presentation)" class="btn btn-link" alt="add a note" data-bs-toggle="modal" data-bs-target="#createNoteModal"><i class="bi bi-pencil-square"></i></button></td>
															</tr>
														</table>
													</td>
												</tr>
											</tbody>
											<tfoot>
												<tr>
													<td colspan="6">
														<button type="button" class="btn btn-warning mt-3" v-if="downloadIDs.length > 0" @click.stop.prevent="downloadFiles">
															<i class="bi bi-download"></i> Download Selected Files
														</button>
													</td>
												</tr>
											</tfoot>
										</table>
									</div>
								</div>
							</div>
							<div v-else>
								<p class="alert alert-danger">There is no presentation schedule for this room in the system...</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="modal fade" id="downloadModal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="downloadModalLabel" aria-hidden="true">
			<div class="modal-dialog">
				<div class="modal-content">
					<div class="modal-header">
						<h5 class="modal-title" id="downloadModalLabel">Download in Progress</h5>
						<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
					</div>
					<div class="modal-body">
						<h4>Your Download is Being Prepared</h4>
						<hr>
						<p>A new tab has been opened and your presentations are being compressed to a ZIP archive.</p>
						<p>Once complete you will see a system message from your browser.<br>
							It will ask if you want to save the ZIP file or open it.</p>
						<p>
							Inside the zip file will be individual directories for each presentations<br>
							the folder will look like "11_00_Doe_John" (24 hour formatted time followed by last and first name)
						</p>
						<p>
							Pro Tip: have a folder on your desktop named today's date:
							<em>(12-25)</em> and drag the folders from the zip to this directory.
							If you already downloaded a presentation and are replacing it the system will ask you if you want to overwrite the current file in the directory.
							Say Yes!
						</p>
					</div>
					<div class="modal-footer">
						<button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
					</div>
				</div>
			</div>
		</div>

		<!-- Modal -->
		<div class="modal fade" id="createNoteModal" tabindex="-1" aria-labelledby="createNoteModalLabel" aria-hidden="true">
			<div class="modal-dialog">
				<div class="modal-content">
					<div class="modal-header">
						<h5 class="modal-title text-dark" id="createNoteModalLabel">Create a Note</h5>
						<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
					</div>
					<div class="modal-body">
						<p class="text-dark">{{createNotePresentation.presentation_title}}</p>
						<p class="text-dark">{{createNotePresentation.presentation_time}}</p>
						<textarea maxlength="250" v-model="createNoteText" class="form-control"></textarea>
					</div>
					<div class="modal-footer">
						<button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
						<button type="button" class="btn btn-primary" @click="processNoteSave()">Save changes</button>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>

export default {
	name: "EventRoomSchedule",
	title: 'SYNC Room Schedule',
	data() {
		return {
			event_roomsID: this.$route.params.ID,
			eventData: {},
			roomData: {},
			presentationDatesArray: [],
			presentationsArray: [],
			fileTypes: [],
			updateTimer: 60,
			downloadIDs: [],
			isSyncMachine: false,
			syncStationID: null,
			selectedTabDate: '',
			availableSyncStations: [],
			refreshInterval: null,
			progressComplete: false,
			progressTotal: 0,
			isCheckAll: false,
			downloadModal: null,
			createNoteText: "",
			createNotePresentation: {presentation_title:"Example", presentation_time: "00:00"},
      firstLoad: true
		}
	},
	computed: {
		syncStation() {
			return this.$store.getters['syncMachine/getSyncMachine'];
		}
	},
	methods: {

		runCheckAll() {
			this.isCheckAll = !this.isCheckAll;
			this.downloadIDs = [];
			if (this.isCheckAll) {
				//the presentations array is an of objects
				// date string : [presentationObjects]
				for (let i = 0; i < this.presentationsArray[this.selectedTabDate].length; i++) {
					this.downloadIDs.push(this.presentationsArray[this.selectedTabDate][i].presentation_scheduleID);
				}
			}
		},
		revisionCount(presentation) {
			let revCount = '--';
			if (presentation.presentationFile) {
				if (presentation.presentationFile.file_revision) {
					revCount = "Rev: "+presentation.presentationFile.file_revision;
				}
			}
			return revCount;
		},
		compareRevision(presentation) {
			let inSync = false;
			if(presentation.presentationFile){
				if (presentation.syncData) {
					if (presentation.syncData.downloaded_revision == presentation.presentationFile.file_revision) {
						inSync = true;
					}
				} else if (!presentation.presentationFile) {
					inSync = true;
				}
				return inSync;
			}
		},
		presentationTime(time) {
			let t = this.dayjs(time, 'HH:m:ss');
			return t.format("h:mm a");
		},
		tabDate(date) {
			let html = '';
			let d = this.dayjs(date, 'YYYY-MM-DD');
			html += '<p class="ps-3 pe-3 mb-0">' + d.format("dddd") + '</br>';
			html += '<em>' + d.format('MMM D') + '</em></p>';
			return html;
		},
		setTabDate(date) {
			this.selectedTabDate = date;
		},
		getPresenterLink(presentation) {
			return 'https://sync.meetingfiles.com/my-sync/auth.php?uuid=' + presentation.presenter.uuid + '&anyscreen=true&pid=' + presentation.presentation_scheduleID;
		},
		getFileTypeSource(fileTypesID){
			//let source = '@/assets/images/';
			let short;
			switch(fileTypesID){
				case 1:
					short = 'PP-Win';
					//source += 'i-win.png';
					break;
				case 2:
					short = 'PP-Mac';
					//source += 'i-mac.png';
					break;
				case 3:
					short = 'Vid';
					//source += 'i-play.png';
					break;
				case 4:
					short = 'Key';
					//source += 'i-key.png';
					break;
				case 5:
					short = 'PDF';
					//source += 'i-pdf.png';
					break;
				case 6:
					short = 'WRD';
					//source += 'i-word.png';
					break;
				case 7:
					short += '?';
					//source += 'i-other.png';
					break;
				default:
					//source += 'blank.png';
					short += 'N/A';
					break;
			}
			return short;
		},
		getFileTypes(){
			let params = {};
			this.axios.get("FileType", {params: params}).then((r) => {
				if (r.data.status == 200) {
					this.fileTypes = r.data.record;
				} else {
					//404 - no record
					console.log(r);
				}
			});
		},
		initiateSyncFromStore() {
			let storedSyncStation = this.$store.getters['syncMachine/getSyncMachine'];
			if (storedSyncStation && storedSyncStation.event_roomsID == this.event_roomsID) {
				this.isSyncMachine = true;
				this.syncStationID = storedSyncStation.sync_stationsID;
				this.refreshSchedule();
				this.startRefreshCycle();
			} else {
				this.checkIfSyncStation();
			}
		},
		checkIfSyncStation() {
			let params = {method: 'checkSyncStationByRoom', event_roomsID: this.event_roomsID};
			this.axios.get("SyncStation", {params: params}).then((r) => {
				if (r.data.status == 200) {
					this.$store.dispatch('syncMachine/setSyncMachine', r.data.record);
					this.isSyncMachine = true;
					this.syncStationID = r.data.record.sync_stationsID;
					this.refreshSchedule();
					this.startRefreshCycle();
				} else {
					//404 - no record
					this.refreshSchedule();
				}
			});
		},
		startRefreshCycle() {
			let self = this;
			this.refreshInterval = setInterval(function () {
				if (self.updateTimer === 0) {
					self.refreshSchedule();
					self.updateTimer = 60;
				} else {
					self.updateTimer -= 1;
				}
			}, 1000);
		},
		refreshSchedule() {
			this.axios.get('/EventRoomSchedule', {
				params: {
					event_roomsID: this.event_roomsID,
					sync_stationsID: this.syncStationID
				}
			}).then((response) => {
				this.eventData = response.data.record['event'];
				this.roomData = response.data.record['room'];

				if (response.data.record.presentations) {
					this.hasPresentations = true;
					this.presentationsArray = response.data.record['presentations'];
					for (let key in response.data.record['presentations']) {
						this.presentationDatesArray.push(key);
					}
				}

				if (!this.selectedTabDate) {
					this.setTabDate(Object.keys(this.presentationsArray)[0]);
				}

				if (!this.isSyncMachine) {
					this.setAvailableSyncStations();
				}
			});
		},
		setAvailableSyncStations() {
			if (this.eventData) {
				let params = {method: 'getStationsByEvent', eventsID: this.eventData.eventsID}
				this.axios.get("SyncStation", {params: params}).then((r) => {
					if (r.data.status == 200) {
						this.availableSyncStations = r.data.record;
					}
				});
			}
		},
		selectSyncStation(event) {
			if (event.target.value !== '') {
				if (!this.$store.getters['syncMachine/getSyncMachine']) {
					this.startSync(event.target.value);
				} else {
					if (confirm("This machine is currently assigned to a Sync Station. Do you want to change it to the one you selected?")) {
						this.stopSync();
						this.startSync(event.target.value);
					}
				}
			} else {
				//selecting the blank option stops sync and updates the Server.
				if (this.isSyncMachine) {
					this.stopSync();
				}
			}
		},
		startSync(syncStationID) {
			let params = {
				method: 'setSyncStationForRoom',
				event_roomsID: this.event_roomsID,
				sync_stationsID: syncStationID
			}
			this.axios.patch('/SyncStation', params).then((r) => {
				if (r.data.status === 200) {
					this.setSyncMachineObject(syncStationID);
				} else {
					console.log("error starting sync");
					console.log(r);
				}
			});
		},
		stopSync() {
			if (confirm("Do You Want To Stop This Machines Sync For This Room?")) {
				let params = {
					method: 'stopSyncStationForRoom',
					event_roomsID: this.event_roomsID,
					sync_stationsID: this.syncStation.sync_stationsID
				}
				this.axios.patch('/SyncStation', params).then((r) => {
					if (r.data.status === 200) {
						this.$store.dispatch('syncMachine/setSyncMachine', null);
						this.isSyncMachine = false;
						this.syncStationID = null;
						clearInterval(this.refreshInterval);
						this.setAvailableSyncStations();
					}
				});
			}
		},
		setSyncMachineObject(syncStationID) {
			for (let i = 0; i < this.availableSyncStations.length; i++) {
				if (this.availableSyncStations[i].sync_stationsID == syncStationID) {
					if (!this.availableSyncStations[i].room_name) {
						this.availableSyncStations[i].room_name = this.roomData.room_name;
					}
					this.$store.dispatch('syncMachine/setSyncMachine', this.availableSyncStations[i]).then(() => {
						this.isSyncMachine = true;
						this.syncStationID = syncStationID;
						this.refreshSchedule();
						this.startRefreshCycle();
					});
				}
			}
		},
		/**
		 * Downloads
		 */
		handleDownloadCheckbox() {
			//compare check all
			if (this.downloadIDs.length == this.presentationsArray[this.selectedTabDate].length) {
        console.log('length matched length');
				this.isCheckAll = true;
			} else {
        console.log('length NOT length');
				this.isCheckAll = false;
			}
		},
		addToDownload(presentationFileID) {
			this.downloadIDs.push(presentationFileID);
		},
		removeFromDownload(presentationFileID) {
			for (let i = 0; i <= this.downloadIDs.length; i++) {
				if (this.downloadIDs[i] === presentationFileID) {
					this.downloadIDs.splice(i, 1);
				}
			}
		},
		downloadFiles() {
			if (this.downloadIDs.length > 0) {
				//overlay
				//this.downloadModal.show();
				let syncStationID = '';
				if(this.syncStation.sync_stationsID != null && this.syncStation.sync_stationsID != '' && this.syncStation.sync_stationsID != null){
					syncStationID = this.syncStation.sync_stationsID;
				}
        console.log("download all");
        console.log(`https://sync.meetingfiles.com/api/public/download/?${this.downloadIDs.map((n, index) => `scheduleIDs[${index}]=${n}`).join('&')}&room=` + encodeURI(this.roomData.room_name) + `&date=` + encodeURI(this.selectedTabDate) + `&syncStationID=` + syncStationID);
				window.open(`https://sync.meetingfiles.com/api/public/download/?${this.downloadIDs.map((n, index) => `scheduleIDs[${index}]=${n}`).join('&')}&room=` + encodeURI(this.roomData.room_name) + `&date=` + encodeURI(this.selectedTabDate) + `&syncStationID=` + syncStationID);

				//uncheck
				//collapse button
				this.isCheckAll = false;
				this.runCheckAll();

			} else {
				alert("No Presentations Selected");
			}
		},
		createNoteClicked(presentation){
			this.createNotePresentation = presentation;
		},
		processNoteSave(){
			let params = {ID: this.createNotePresentation.presentation_scheduleID, admin_notes: this.createNoteText};
			this.axios.patch('/PresentationSchedule', params).then((r) => {
				if (r.data.status === 200) {

					for(let i = 0; i < this.presentationsArray[this.selectedTabDate].length; i++){
						if (this.presentationsArray[this.selectedTabDate][i].presentation_scheduleID === r.data.record['presentation_scheduleID']){
							console.log("found it");
							this.presentationsArray[this.selectedTabDate][i] = r.data.record;
						}
					}
				} else {
					console.log("error");
					console.log(r);
				}
			});
		},
    /** Update Time **/
    updateTime(e, presentation){
      let data = {
        ID: presentation.presentation_scheduleID,
        presentation_time: e.target.innerText
      }
      this.axios.patch('/PresentationSchedule', JSON.stringify(data)).then((response) => {
        if (response.status == 200) {
          this.refreshSchedule();
        } else {
          alert("Could not update the presentation on the server\n" + response.message);
        }
      });
    },
    /** Update Time **/
    updateTitle(e, presentation){
      let data = {
        ID: presentation.presentation_scheduleID,
        presentation_title: e.target.innerText
      }
      this.axios.patch('/PresentationSchedule', JSON.stringify(data)).then((response) => {
        if (response.status == 200) {
          this.refreshSchedule();
        } else {
          alert("Could not update the presentation on the server\n" + response.message);
        }
      });
    }
	},
	watch: {
		'$route.params': {
			handler(newValue) {
				if (newValue.ID && newValue.ID !== undefined && newValue.ID !== '') {
					this.event_roomsID = newValue.ID;
					this.getFileTypes();
					this.initiateSyncFromStore();
				}
			},
			immediate: true,
		}
	}
}
</script>

<style scoped>
	.note-td{
		background-color: #fce587;
		color: #282d36;
	}
	.td-dl{
		width: 32px;
		text-align: center;
	}
	.td-time{
		width: 75px;
	}
	.td-presenter{
		width: 200px;
	}
	.td-type{
		width: 75px;
	}
	.td-title{

	}
	.td-rev{
		width: 50px;
	}
	.td-buttons{
		width: 100px;
	}
</style>
