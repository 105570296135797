<template>
  <div id="presenterView" class="">
    <div class="card mb-3">
      <div class="card-header">
        <h4>Search Presenters</h4>
      </div>
      <div class="card-body">
        <div class="mb-3">
          <label class="form-label">Enter Presenter Name</label>
          <input type="text" class="form-control" v-model="searchName" @keyup="searchPresenters"/>
        </div>
        <div v-if="showSearchResults">
          <table class="table">
            <tbody>
            <tr v-for="searchResult in searchResults" :key="searchResult.presentersID">
              <td>{{ searchResult.presenters_fname }}</td>
              <td>{{ searchResult.presenters_lname }}</td>
              <td>{{ searchResult.presenters_email }}</td>
              <td class="btn-group">
                <button class="btn btn-sm btn-secondary" @click="editPresenter(searchResult)">
                  <i class="bi bi-pencil-square"></i> edit presenter
                </button>
                <button class="btn btn-sm btn-secondary" @click="goToSchedule(searchResult)">
                  <i class="bi bi-file-earmark-plus"></i> presenter schedule
                </button>
                <button class="btn btn-sm btn-secondary" @click="goToSync(searchResult)">
                  <i class="bi bi-link-45deg"></i> goto sync
                </button>
                <a :href="'https://sync.meetingfiles.com/my-sync/?uuid='+searchResult.uuid+'&pin='+searchResult.presenters_pin" class="btn btn-sm btn-secondary">PLink</a>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
        <p v-if="showNoSearchResultsAlert" class="alert alert-danger">
          <i class="bi bi-exclamation-octagon-fill"></i> No Results for this Name</p>
      </div>
    </div>
    <div class="card">
      <div class="card-header">
        <h4 v-if="!editing">Add a New Presenter</h4>
        <h4 v-else>Edit Presenter</h4>
      </div>
      <div class="card-body">
        <div v-if="showMsg" class="alert" v-bind:class="[ isError ? 'alert-danger':'alert-success' ]"><p>
          <i class="bi" v-bind:class="[ isError ? 'bi-exclamation-octagon-fill':'bi-check-circle-fill' ]"></i>
          {{ formMsg }}
        </p></div>
        <form id="addPresenter" @submit.prevent="createNewPresenter">
          <div class="mb-2">
            <label class="form-label">First Name</label>
            <input type="text" name="presenter_fname" class="form-control" v-model="newPresenterFname"/>
          </div>
          <div class="mb-2">
            <label class="form-label">Last Name</label>
            <input type="text" name="presenter_lname" class="form-control" v-model="newPresenterLname"/>
          </div>
          <div class="mb-2">
            <label class="form-label">Email</label>
            <input type="text" name="presenter_email" class="form-control" v-model="newPresenterEmail"/>
          </div>

          <div class="mb-2">
            <input type="hidden" name="presentersID" v-model="newPresentersID"/>
            <input type="submit" name="submit" value="submit" class="btn btn-success"/>
          </div>
        </form>
      </div>
      <div class="card-footer"></div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'PresenterView',
  title: 'SYNC Presenters',
  data() {
    return {
      newPresenterFname: '',
      newPresenterLname: '',
      newPresenterEmail: '',
      newPresentersID: '',
      showMsg: false,
      isError: false,
      formMsg: '',
      newPresenter: {},
      searchName: '',
      searchResults: [],
      showSearchResults: false,
      showNoSearchResultsAlert: false,
      editing: false
    }
  },
  methods: {
    createNewPresenter: function () {
      if (this.newPresenterFname != '' && this.newPresenterLname != '' && this.newPresenterEmail != '') {
        this.newPresenter = {
          presenters_fname: this.newPresenterFname,
          presenters_lname: this.newPresenterLname,
          presenters_email: this.newPresenterEmail
        };
      } else {
        /**ToDo
         * Handle empty form
         **/
      }
      if (this.isEditing) {
        this.newPresenter['ID'] = this.newPresentersID;
        this.axios.patch('/Presenter', this.newPresenter).then(response => (this.processNewPresenterResponse(response.data)));
      } else {
        this.axios.post('/Presenter', this.newPresenter).then(response => (this.processNewPresenterResponse(response.data)));
      }
    },
    searchPresenters: function () {
      let parameters = {searchClass: 'Presenter', searchMethod: 'searchByNames', term: this.searchName}
      if (this.searchName.length >= 3) {
        this.axios.get("/public/search/", {params: parameters}).then(response => (this.processSearchResults(response.data)));
      } else {
        this.showSearchResults = false;
        this.searchResults = [];
      }
    },
    processNewPresenterResponse: function (response) {
      if (response.status === 200) {
        let msg = this.isEditing ? 'Presenter Edited' : 'New Presenter Created';
        this.isError = false;
        this.formMsg = msg;
        this.showMsg = true;
      } else if (response.status === 409) {
        this.isError = true;
        this.formMsg = 'No Duplicate Records. A presenter with this email already exists.';
        this.showMsg = true;
      } else {
        console.log('unknown error');
      }
    },
    processSearchResults: function (data) {
      if (data.record.length > 0) {
        this.showNoSearchResultsAlert = false;
        this.showSearchResults = true;
        this.searchResults = data.record;
      } else {
        this.showNoSearchResultsAlert = true;
      }
    },
    editPresenter(presenter) {
      this.isEditing = true;
      console.log(presenter);
      this.newPresenterFname = presenter.presenters_fname;
      this.newPresenterLname = presenter.presenters_lname;
      this.newPresenterEmail = presenter.presenters_email;
      this.newPresentersID = presenter.presentersID;
    },
    goToSchedule(selectedPresenter) {
      let params = {ID: selectedPresenter.presentersID}
      this.$router.push({name: 'PresenterScheduleView', params});
    },
    goToSync(presenter) {
      let url = encodeURI('https://sync.meetingfiles.com/my-sync/auth.php?anyscreen=true&uuid=' + presenter.uuid);
      window.open(url, "_blank");
    },
    setPresenter(presentersID) {
      this.axios.get('/Presenter/' + presentersID).then((response) => {
        if (response.data.status == 200) {
          this.editPresenter(response.data.record);
          this.editing = true;
        }
      }, (error) => {
        console.log(error);
      });
    }


  },
  mounted() {
    //do we have a presenter ID?
    if (this.$route.params.ID) {
      this.setPresenter(this.$route.params.ID);
    }
  }
}
</script>

<style scoped>

</style>
